<template>
  <div class="container">
    <PublicHeader :totalName="totalName" />
    <van-field
      v-model="oldPassword"
      label="旧密码"
      type="password"
      placeholder="请输入旧密码"
    />
    <van-field
      v-model="Password"
      type="password"
      label="新密码"
      placeholder="请输入新密码"
    />
    <van-field
      v-model="submitPasswork"
      label="确认新密码"
      type="password"
      placeholder="确认新密码"
    />
    <van-button size="large" @click="handlePassword">确认修改</van-button>
  </div>
</template>

<script>
import PublicHeader from '@/components/publicHeader'
import userApi from '@/api/user'
export default {
  components: {
    PublicHeader,
  },
  data() {
    return {
      totalName: '修改密码',
      Password: '',
      oldPassword: '',
      submitPasswork: '',
    }
  },
  methods: {
    onConfirm(value) {
      this.bankName = value
      this.show = false
    },
    checkPasswork() {
      if (this.oldPassword.length === '' || this.oldPassword.length < 6) {
        this.$toast({
          message: '请输入至少6位的旧密码',
          position: 'bottom',
        })
        return false
      }

      if (this.Password.length === '' || this.Password.length < 6) {
        this.$toast({
          message: '请输入至少6位的新密码',
          position: 'bottom',
        })
        return false
      }
      if (this.submitPasswork.length === '' || this.submitPasswork.length < 6) {
        this.$toast({
          message: '请输入正确的确认密码',
          position: 'bottom',
        })
        return false
      }
      if (this.Password !== this.submitPasswork) {
        this.$toast({
          message: '两次输入的新密码不一致',
          position: 'bottom',
        })
        return false
      }
      return true
    },
    async handlePassword() {
      if (!this.checkPasswork()) {
        return
      }
      await userApi.editPassword({
        newPassword: this.Password,
        oldPassword: this.oldPassword,
      })
      this.$toast.success('修改密码成功')
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="stylus" scoped>
@import "~@/styles/mixin.styl";
.container
  width: 100%;
  background-color #1f1f1f
  font-size: 14px
  color #fae39c
  overflow-x hidden
  height 100vh
  padding-top 48.5px
  .van-field
    background none
    margin 0
    >>> .van-field__label
      color #ebe5cd
      margin 0
    >>> .van-field__control
      color #fff
    >>> input::-webkit-input-placeholder
      color #969799
  .van-button
    width calc(100% - 30px)
    border none
    margin 21px 15px 23px
    color #000
    font-size 14px
    height 44px
    background: linear-gradient(315deg,#e8d49e,#d6b372)!important
</style>
